import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";

const useClarity = (clarityId, client_id, email) => {
  useEffect(() => {
    if (!clarityId) {
      console.error("REACT_APP_CLARITY_ID is not set");
      return;
    }

    // Initialization logic for Clarity
    clarity.init(clarityId);

    if (clarity.hasStarted) {
      clarity.setTag("client_id", client_id);
      clarity.setTag("email", email);
    }
  }, [clarityId,client_id,email]);
};

export default useClarity;
