interface EnvConfig {
    API_ENDPOINT_URL: string;
}

const dev: EnvConfig = {
    API_ENDPOINT_URL: import.meta.env.VITE_API_ENDPOINT_URL,
}

const prod: EnvConfig = {
    API_ENDPOINT_URL: import.meta.env.VITE_API_ENDPOINT_URL,
}

const test: EnvConfig = {
    API_ENDPOINT_URL: import.meta.env.VITE_API_ENDPOINT_URL,
}

const getEnv = (): EnvConfig | undefined => {
    switch (import.meta.env.VITE_NODE_ENV) {
        case 'development':
            return dev
        case 'test':
            return test
        case 'production':
            return prod
        default:
            return dev
    }
}

export const env = getEnv()